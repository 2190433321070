<template>
  <v-row>
    <v-col cols="12" sm="6" md="4">
      <v-card class="mt-4 mx-auto" max-width="400">
        <v-sheet
          class="v-sheet--offset mx-auto"
          color="cyan"
          elevation="12"
          max-width="calc(100% - 32px)"
        >
          <v-icon color="indigo" class="mr-12" size="64">
            mdi-account
          </v-icon>
          <span>{{ totalUser }}</span>
        </v-sheet>
        <v-card-text class="pt-0">
          <div class="title font-weight-light mb-2">
            User Registrations
          </div>
          <v-divider class="my-2"></v-divider>
          <v-icon class="mr-2" small>
            mdi-clock
          </v-icon>
          <div>
            <span class="caption grey--text font-weight-light">
              {{ new Date() }}
            </span>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" sm="6" md="4">
      <v-card class="mt-4 mx-auto" max-width="400">
        <v-sheet
          class="v-sheet--offset mx-auto"
          color="#ffd200"
          elevation="12"
          max-width="calc(100% - 32px)"
          v-for="jlhDaftar of jlhDaftar"
          :key="jlhDaftar.jlhDaftar"
        >
          <v-icon color="indigo" class="mr-12" size="64">
            mdi-account-check
          </v-icon>
          <span>{{ jlhDaftar.jlhDaftar }}</span>
        </v-sheet>
        <v-sheet
          class="v-sheet--offset mx-auto"
          color="#FF00FF"
          elevation="12"
          max-width="calc(100% - 32px)"
          v-for="jlhPst of jlhPst"
          :key="jlhPst.jlhPst"
        >
          <v-icon color="indigo" class="mr-12" size="64">
            mdi-account-clock-outline
          </v-icon>
          <span>{{ jlhPst.jlhPst }}</span>
        </v-sheet>
        <v-card-text class="pt-0">
          <div class="title font-weight-light mb-2">
            Peserta Terdafar
          </div>
          <v-divider class="my-2"></v-divider>
          <v-icon class="mr-2" small>
            mdi-clock
          </v-icon>
          <div>
            <span class="caption grey--text font-weight-light">
              {{ new Date() }}
            </span>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" sm="6" md="4">
      <v-card class="mt-4 mx-auto" max-width="400">
        <v-sheet
          class="v-sheet--offset mx-auto"
          color="#00cc00"
          elevation="12"
          max-width="calc(100% - 32px)"
          v-for="lulus of lulus"
          :key="lulus.lulus"
        >
          <v-icon color="indigo" class="mr-12" size="64">
            mdi-emoticon-happy-outline
          </v-icon>
          <span>{{ lulus.lulus }}</span>
        </v-sheet>
        <v-sheet
          class="v-sheet--offset mx-auto"
          color="red"
          elevation="12"
          max-width="calc(100% - 32px)"
          v-for="gagal of gagal"
          :key="gagal.gagal"
        >
          <v-icon color="indigo" class="mr-12" size="64">
            mdi-emoticon-sad-outline
          </v-icon>
          <span>{{ gagal.gagal }}</span>
        </v-sheet>
        <v-card-text class="pt-0">
          <div class="title font-weight-light mb-2">
            Peserta Lulus Tes CBT
          </div>
          <v-divider class="my-2"></v-divider>
          <v-icon class="mr-2" small>
            mdi-clock
          </v-icon>
          <div>
            <span class="caption grey--text font-weight-light">
              {{ new Date() }}
            </span>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" sm="6" md="4">
      <v-card class="mt-4 mx-auto" max-width="400">
        <v-sheet
          class="v-sheet--offset mx-auto"
          color="blue"
          elevation="12"
          max-width="calc(100% - 32px)"
          v-for="dafarUlang of dafarUlang"
          :key="dafarUlang.jlhReg"
        >
          <v-icon color="indigo" class="mr-12" size="64">
            mdi-account
          </v-icon>
          <span>{{ dafarUlang.jlhReg }}</span>
        </v-sheet>
        <v-card-text class="pt-0">
          <div class="title font-weight-light mb-2">
            Registrasi Ulang
          </div>
          <v-divider class="my-2"></v-divider>
          <v-icon class="mr-2" small>
            mdi-clock
          </v-icon>
          <div>
            <span class="caption grey--text font-weight-light">
              {{ new Date() }}
            </span>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" sm="8" md="8">
      <v-card class="mx-auto text-center" color="green" dark max-width="800">
        <v-card-text>
          <v-sheet color="rgba(0, 0, 0, .12)">
            <bar-chart
              :chartdata="jlhProdi"
              :options="options"
              label="Jumlah Peserta"
            />
          </v-sheet>
        </v-card-text>
        <v-card-text>
          <span class="  caption  font-weight-thin">
            {{ new Date() }}
          </span>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn block text :to="`/peserta`">
            Go to Report
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import BarChart from "../components/barChart.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "dashboard",
  data: () => ({
    tes: [],
    options: {
      legend: {
        position: "top"
      },
      scales: {
        xAxes: [
          {
            stacked: true
          }
        ],
        yAxes: [
          {
            stacked: true
          }
        ]
      },
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        mode: "index",
        intersect: false
      }
    }
  }),
  components: {
    BarChart
  },
  computed: {
    ...mapGetters("users", ["totalUser"]),
    ...mapGetters("dashboard", [
      "jlhDaftar",
      "jlhPst",
      "lulus",
      "gagal",
      "jlhProdi",
      "dafarUlang"
    ])
  },

  created() {
    this.loadUser();
    this.loadDataDahs();
  },

  methods: {
    ...mapActions("users", ["loadUser"]),
    ...mapActions("dashboard", ["loadDataDahs"])
  }
};
</script>
<style lang="scss" scoped>
.v-sheet--offset {
  top: -25px;
  position: relative;
  text-align: center;
  font-size: 50px;
}
</style>
