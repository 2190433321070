<script>
import { Bar } from "vue-chartjs";

export default {
  mixins: [Bar],
  props: {
    label: {
      type: String
    },
    chartdata: {
      type: Array,
      default: null
    },
    options: {
      type: Object,
      default: null
    }
  },
  watch: {
    chartdata() {
      this.$data._chart.update();
    }
  },
  mounted() {
    const prodi = this.chartdata.map(d => d.nama_program_studi).reverse();
    const jumlah = this.chartdata.map(d => d.jml).reverse();
    this.renderChart(
      {
        labels: prodi,
        datasets: [
          {
            label: this.label,
            backgroundColor: "#f87979",
            data: jumlah,
            stack: "Stack 1"
          }
        ]
      },
      this.options
    );
  }
};
</script>
